export interface Album {
  key: string
  heading: string
  date: string
  text?: string[]
  leadIndex?: number
  thumbWidths: number[]
  indexMapCaption: Record<number, string | undefined>
}

export const albumUrlPrefix = (album: Album) =>
  `https://s3-ap-southeast-2.amazonaws.com/outin.world/${album.key}/`

export const THUMB_DISPLAY_H = 250
export const THUMB_IMAGE_H = THUMB_DISPLAY_H * 2 // For retina displays

export const fullToThumbPath = (fullPath: string) =>
  fullPath.replace('.jpg', `-${THUMB_IMAGE_H}h.jpg`)

export const indexToFilename = (index: number) => {
  const seq = String(index + 1).padStart(3, '0')
  return `${seq}.jpg`
}

export const yearFromDate = (date: string) => date.substring(0, 4)

export const newestAlbumFirst = (a: Album, b: Album): number =>
  b.date.localeCompare(a.date)
