import React, { FC } from 'react'
// @ts-ignore
import Gallery from 'react-grid-gallery'
import { RouteComponentProps } from '@reach/router'
import { albums } from './albums'
import { navigate } from './navigate'
import {
  albumUrlPrefix,
  fullToThumbPath,
  indexToFilename,
  THUMB_DISPLAY_H,
  THUMB_IMAGE_H,
} from './shared'

const RETINA_DISPLAY_WIDTH = 2880

export const AlbumGallery: FC<RouteComponentProps<{ albumKey: string }>> = ({
  albumKey,
}) => {
  const album = albums.find((a) => a.key === albumKey)
  if (!album) {
    console.log(`Unknown album: ${albumKey}`)
    navigate('/')
    return null
  }
  const prefix = albumUrlPrefix(album)
  const shortText = album.text?.length === 1 && !album.text[0].includes('.')
  return (
    <div>
      <h1>{album.heading}</h1>
      {album.text?.map((para) => (
        <p style={shortText ? { textAlign: 'center' } : {}}>{para}</p>
      ))}
      <div className="gallery-container">
        <Gallery
          images={album.thumbWidths.map((thumbnailWidth, i) => {
            const filename = indexToFilename(i)
            return {
              key: `${i}`,
              caption: album.indexMapCaption[i],
              src: prefix + filename,
              thumbnail: prefix + fullToThumbPath(filename),
              thumbnailWidth,
              thumbnailHeight: THUMB_IMAGE_H,
            }
          })}
          enableImageSelection={false}
          rowHeight={THUMB_DISPLAY_H}
          lightboxWidth={RETINA_DISPLAY_WIDTH}
        />
      </div>
    </div>
  )
}
