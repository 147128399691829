import React, { FC } from 'react'
// @ts-ignore
import Gallery from 'react-grid-gallery'
import { navigate } from './navigate'
import { albums } from './albums'
import {
  albumUrlPrefix,
  fullToThumbPath,
  indexToFilename,
  newestAlbumFirst,
  THUMB_DISPLAY_H,
  THUMB_IMAGE_H,
  yearFromDate,
} from './shared'

const YearHeading: FC = ({ children }) => (
  <div style={{ display: 'block' }}>
    <h2>{children}</h2>
  </div>
)

const Year: FC<{
  year: string
}> = ({ year }) => {
  function selectAlbum(this: any) {
    navigate(`/album/${this.props.item.key}`)
  }

  return (
    <div>
      <YearHeading>{year}</YearHeading>
      <div className="gallery-container">
        <Gallery
          images={albums
            .filter((album) => album.date.startsWith(year))
            .sort(newestAlbumFirst)
            .map((album) => {
              const prefix = albumUrlPrefix(album)
              const leadIndex = album.leadIndex ?? 0
              const filename = indexToFilename(leadIndex)
              return {
                key: album.key,
                src: prefix + filename,
                thumbnail: prefix + fullToThumbPath(filename),
                thumbnailWidth: album.thumbWidths[leadIndex],
                thumbnailHeight: THUMB_IMAGE_H,
                thumbnailCaption: (
                  <div className="album-caption">{album.heading}</div>
                ),
              }
            })}
          enableImageSelection={false}
          enableLightbox={false}
          rowHeight={THUMB_DISPLAY_H}
          onClickThumbnail={selectAlbum}
        />
      </div>
    </div>
  )
}

export const YearsGallery: FC = () => {
  const years = Array.from(
    new Set(Object.values(albums).map(({ date }) => yearFromDate(date))),
  )
    .sort()
    .reverse()
  return (
    <>
      {years.map((year) => (
        <Year key={year} year={year} />
      ))}
    </>
  )
}
