import React, { FC } from 'react'
// @ts-ignore
import { RouteComponentProps, Router } from '@reach/router'
import { YearsGallery } from './YearsGallery'
import { AlbumGallery } from './AlbumGallery'

const Landing: FC<RouteComponentProps> = () => (
  <>
    <h1>Out in the World</h1>
    <p>
      We met. Pinky promised. Sold everything. USA. 15-year-old Corolla. Two
      year road trip. Canada, Mexico, Central America. Pregnant. Headed home.
      Welcome Marlo. 5-months-old. Two backpacks. East Asia. Central Europe.
      South Asia. Himalayas. Northern Europe. China. COVID.
    </p>
    <p>
      Another way is always possible. Crazys are given leeway. Be out there.
    </p>
    <p className="signature">Damon, Melissa, and Marlo</p>
    <YearsGallery />
  </>
)

const App = () => (
  <Router>
    <Landing path="/" />
    <AlbumGallery path="album/:albumKey" />
  </Router>
)

export default App
